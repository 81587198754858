import * as React from "react"
import { useState } from "react"
import Seo from "../components/seo"
import Container from "../components/Container"
import Markdown from "../components/Markdown"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import "twin.macro"
import Lightbox from "../components/Lightbox"
import { ArrowLeft } from "react-feather"
import { Link, navigate } from "gatsby"
import Button from "../components/Button"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperClass, Navigation, Thumbs, Controller } from "swiper"
interface ImageWithTitle {
  title: string
  image: ImageDataLike
}

interface GalleryImageProps {
  pageContext: {
    image: {
      childMarkdownRemark: {
        html: string
        frontmatter: {
          title: string
          sold: boolean
          image: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike
            }
          }
          imageCloseup: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike
            }
          } | null
          moreImages: Array<{
            title: string
            image: {
              childImageSharp: {
                gatsbyImageData: ImageDataLike
              }
            }
          }> | null
        }
      }
    }
  }
}

const GalleryImage: React.FC<GalleryImageProps> = ({ pageContext }) => {
  const {
    html,
    frontmatter: {
      title,
      sold,
      image: {
        childImageSharp: { gatsbyImageData: imageData },
      },
      imageCloseup,
      moreImages: moreImageData,
    },
  } = pageContext.image.childMarkdownRemark

  const closeUpData = imageCloseup?.childImageSharp?.gatsbyImageData

  const image = getImage(imageData)
  const closeUp = closeUpData ? getImage(closeUpData) : undefined

  const [lightboxOpen, setLightboxOpen] = useState(false)

  const lightboxImages = []

  if (image) {
    lightboxImages.push({
      title,
      image,
    })
  }

  if (closeUp) {
    lightboxImages.push({
      title: "Close up",
      image: closeUp,
    })
  }

  moreImageData?.forEach(moreImageData => {
    const additionalImage = getImage(
      moreImageData.image.childImageSharp.gatsbyImageData
    )

    if (additionalImage) {
      lightboxImages.push({
        title: moreImageData.title,
        image: additionalImage,
      })
    }
  })

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null)
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass[]>([])

  const addControlledSwiper = (swiper: SwiperClass) => {
    setControlledSwiper([...controlledSwiper, swiper])
  }

  return (
    <>
      <Seo title={title} />
      <Container>
        <div tw="grid grid-cols-1 lg:grid-cols-2 grid-rows-[auto_auto_auto] lg:grid-rows-[auto_auto] gap-4 lg:gap-x-20">
          <div tw="flex justify-start lg:col-span-2">
            <Button onClick={() => navigate(-1)} variant="link">
              <div tw="flex gap-2">
                <ArrowLeft />
                <div>Back</div>
              </div>
            </Button>
          </div>
          <div tw="">
            <Swiper
              modules={[Thumbs, Controller]}
              thumbs={{ swiper: thumbsSwiper }}
              controller={{ control: controlledSwiper }}
              loop
              tw="mb-4"
            >
              {lightboxImages.map(({ image, title }) => (
                <SwiperSlide key={title}>
                  <GatsbyImage
                    image={image}
                    alt={title}
                    tw="cursor-pointer"
                    style={{ aspectRatio: "1" }}
                    objectFit="cover"
                    onClick={() => setLightboxOpen(true)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              modules={[Thumbs]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              slidesPerView={4}
              spaceBetween={16}
            >
              {lightboxImages.map(({ image, title }) => (
                <SwiperSlide key={title}>
                  <GatsbyImage
                    image={image}
                    alt={title}
                    style={{ aspectRatio: "1" }}
                    objectFit="cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Lightbox
              images={lightboxImages}
              open={lightboxOpen}
              addControlledSwiper={addControlledSwiper}
              onClose={() => setLightboxOpen(false)}
            />
          </div>
          <section tw="">
            <div tw="mb-6">
              <h2 tw="text-3xl font-display">{title}</h2>
              {sold && <span tw="text-sm">SOLD</span>}
            </div>
            <div dangerouslySetInnerHTML={{ __html: html }} tw="prose mb-2" />
            <div tw="prose mb-12">
              <ul>
                <li>Finished with varnish to protect against UV</li>
                <li>Signed and dated on back</li>
                <li>Includes signed certificate of authenticity</li>
              </ul>
              <p>
                Frames shown for illustrative purposes only and are not included
              </p>
              <em>
                Please note that colours will vary slightly from screen to
                screen.
              </em>
            </div>
            <Link to="/contact">
              <Button>Contact me</Button>
            </Link>
          </section>
        </div>
      </Container>
    </>
  )
}

export default GalleryImage
