import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { ChevronLeft, ChevronRight } from "react-feather"
import "twin.macro"
import tw, { css } from "twin.macro"
import {
  Swiper as SwiperClass,
  Navigation,
  Pagination,
  Controller,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { HTMLAttributes } from "react"

interface LightboxNavigationProps extends HTMLAttributes<HTMLDivElement> {
  position: "left" | "right"
}

export const LightboxNavigation: React.FC<LightboxNavigationProps> = ({
  position,
  ...rest
}) => {
  return (
    <div
      tw="absolute top-0 z-10 
      flex items-center h-full p-4 md:p-6 lg:p-8 
      bg-black bg-opacity-0 hover:bg-opacity-20 text-white 
      cursor-pointer transition duration-300 ease-out"
      css={[
        position === "left" && tw`left-0`,
        position === "right" && tw`right-0`,
      ]}
      {...rest}
    >
      {position === "left" && <ChevronLeft size={32} />}
      {position === "right" && <ChevronRight size={32} />}
    </div>
  )
}

interface LightboxSlideProps {
  image: IGatsbyImageData
  alt: string
}

export const LightboxSlide: React.FC<LightboxSlideProps> = ({ image, alt }) => {
  return (
    <div tw="flex justify-center h-full lg:p-8 ">
      <GatsbyImage
        image={image}
        alt={alt}
        tw="max-h-full"
        objectFit="contain"
        loading="lazy"
      />
    </div>
  )
}

export interface LightboxImage {
  image: IGatsbyImageData
  title: string
}

interface LightboxProps {
  images: LightboxImage[]
  open: boolean
  addControlledSwiper?: (swiper: SwiperClass) => unknown
  onClose?: () => unknown
}

const Lightbox: React.FC<LightboxProps> = ({
  open,
  images,
  addControlledSwiper,
  onClose,
}) => {
  return (
    <div
      tw="flex justify-between fixed top-0 left-0 z-50 
        w-full h-full
       opacity-0
       pointer-events-none 
       transition duration-500 ease-out"
      css={[open && tw`pointer-events-auto opacity-100`]}
      aria-hidden={!open}
    >
      <div
        tw="absolute top-0 left-0 w-full h-full
        bg-black bg-opacity-50
       backdrop-filter backdrop-blur"
      />
      <Swiper
        modules={[Navigation, Pagination, Controller]}
        navigation={
          images.length > 0 && {
            prevEl: ".previous-slide",
            nextEl: ".next-slide",
          }
        }
        loop
        onClick={onClose}
        onSwiper={addControlledSwiper}
      >
        {images.map(({ image, title }) => (
          <SwiperSlide key={title}>
            <LightboxSlide image={image} alt={title} />
          </SwiperSlide>
        ))}
      </Swiper>
      <LightboxNavigation position="left" className="previous-slide" />
      <LightboxNavigation position="right" className="next-slide" />
    </div>
  )
}

export default Lightbox
