import { ButtonHTMLAttributes } from "react"
import "twin.macro"
import tw from "twin.macro"
import { css } from "@emotion/react"

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => unknown
  variant?: "primary" | "link"
}

const Button: React.FC<ButtonProps> = ({
  children,
  type,
  variant,
  onClick,
}) => {
  variant = variant ?? "primary"

  return (
    <button
      type={type}
      onClick={onClick}
      tw="px-8 py-4 rounded-md
            font-display 
            focus:(ring outline-none) 
            transition ease-out"
      css={[
        variant === "primary" &&
          tw`bg-secondary hover:bg-secondary-dark text-white focus:ring-secondary-light`,
        variant !== "link" && tw`shadow`,
      ]}
    >
      {children}
    </button>
  )
}

export default Button
